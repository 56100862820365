@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
font-family: 'Circular Std';
src: url('./circular-std-medium-500.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

html {
  font-family: 'Circular Std', sans-serif;
}

/**

body {
  background-color: #2E2E2E;
}
*/

header {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.conference-section {
  padding: 20px 30px;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
}

.conference-section h2 {
  text-align: center;
  font-size: 32px;
  padding-bottom: 10px;
  border-bottom: 1px solid #546e7a;
}

.peers-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  justify-content: center;
  align-items: center;
}



.local.peer-video {
  transform: scaleX(-1);
}

.peer-name {
  font-size: 14px;
  text-align: center;
}

.peer-container {
  position: relative;
}

.control-bar {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  justify-content: center;
  z-index: 1;
}

/*
.peer-video {
  height: 250px;
  width: 450px;
  border-radius: 0.5rem;
  object-fit: cover;
  margin: 10px;
  border: 1px solid #191B23;
}

.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #191B23;
  display: flex;
  align-items: center;
  justify-content: center;
}
*/

.control-bar > *:not(:first-child) {
  margin-left: 8px;
}

.btn-control {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #37474f;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  text-align: center;
  background: none;
  cursor: pointer;
}

.hide {
  display: none;
}

.separator {
  font-size: 12px;
}



.center {
  align-items: center;
}

.highlight {
  background: #2E3038;
}

.conference-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.peers-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.viewers-count {
  margin-top: 1rem;
}

.chat-section {
  width: 300px;
  background: #333;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.messages {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.message {
  padding: 0.5rem;
  border-bottom: 1px solid #444;
}

.chat-input {
  display: flex;
}

.chat-input input {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 4px 0 0 4px;
}

.chat-input button {
  padding: 0.5rem;
  border: none;
  background: #0066ff;
  color: white;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.chat-input button:hover {
  background: #0055cc;
}
